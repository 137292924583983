body { background-color: #ffffff; }
body { color: var(--clr-2845); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Jura';
font-weight: 600;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 44px;

}
}
h2 {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 33px;

}
}
h3 {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
h6 {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 33px;

}
@media #{$large-up} {
font-size: 36px;

}
}
.button {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 24px;

}
@media #{$large-up} {
font-size: 26px;

}
}
summary {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Jura';
font-weight: 500;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-2845);}
a:hover {color: var(--clr-2846);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-2847);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-2847);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-2847);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-2848);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-2848);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-2848);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-2849);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-2849);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-2849);
 }
.MES8 {
background-color: var(--clr-2849);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-2849);
color: var(--clr-2845);
 }
.MES9 {
background-color: var(--clr-2849);
color: var(--clr-2845);
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: var(--clr-2845);
 }
 }
.MEC9 li {color: var(--clr-2845-flat);}
cite.MEC9{
color: var(--clr-2845);
}
/* Light:10 */
.MES10 {
background-color: var(--clr-2850);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-2850);
 }
.MES11 {
background-color: var(--clr-2850);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-2851);
color: #ffffff;
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-2851);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-2851);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
 }
.MES14 {
font-size: 12.8px;
 }
cite.MEC14{
font-size: 12.8px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-2849);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2850);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-2849);}
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2850);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC17{color: var(--clr-2848);
}
 }
.menu-item.MEC17 { border-width: 0 0 2px 0;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-2848); }
 }
&.horizontal > .menu-item.MEC17 { border:0;
border-color: transparent;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;
border-color: transparent;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 20px;}


& > .menu-item.MEC17.active { & > a{ color: var(--clr-2848);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-2852);
color: #ffffff;
border-radius: 50px;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {background-color: 1;
color: var(--clr-2853);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 1;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 1;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 6;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 6;}
}}
& .slick-dots{bottom:20px;
} }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-2850);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-2847);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* Buttons:21 */
.MES21 {
background-color: var(--clr-2850);
&:hover {background-color: var(--clr-2854);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-2850);
font-size: 12.8px;
 }
.MES22 {
background-color: var(--clr-2850);
font-size: 12.8px;
 }
cite.MEC22{
font-size: 12.8px;
}
/* carousel padding:23 */
.MES23 {
padding: 10px;

 }
.MES23 {
padding: 10px;

 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-2849);
color: var(--clr-2845);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
 }
.MES24 {
background-color: var(--clr-2849);
color: var(--clr-2845);
padding: 20px 15px;

@media #{$large-up} {
padding: 20px;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: var(--clr-2845);
 }
 }
cite.MEC24{
color: var(--clr-2845);
}
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-2849) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-2847);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2850) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-2847);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-2850) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-2847);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 35.2px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 44px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 28px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 33px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 26.4px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 36px; }; }
 }
a.MEC26 { color: var(--clr-2847);
&:hover { color: var(--clr-2846);}
 }
cite.MEC26{
color: var(--clr-2847);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* White BG Transparent:27 */
.MES27 {
background-color: var(--clr-2853);
 }
.MES27 {
background-color: var(--clr-2853);
 }
/* Popup form Window:28 */
.MES28 {
background-color: #ffffff;
color: var(--clr-2845);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

 }
.MES28 {
background-color: #ffffff;
color: var(--clr-2845);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: var(--clr-2848);
 }
 }
cite.MEC28{
color: var(--clr-2845);
}
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-2849);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-2849);}
 }
/* SOLD Panel Red text:30 */
.MES30 {
background-color: var(--clr-2851);
color: #ffffff;
min-height:150px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES30 {
background-color: var(--clr-2851);
color: #ffffff;
min-height:150px;
@media #{$medium-up} {
min-height: 150px;};
@media #{$large-up} {
min-height: 200px;};
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
cite.MEC30{
color: #ffffff;
}
/* Header Styles:31 */
.MES31 {
color: #ffffff;
 }
.MES31 {
color: #ffffff;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
a.MEC31 { color: #ffffff;
&:hover { color: var(--clr-2848);}
 }
cite.MEC31{
color: #ffffff;
}
/* Dark:32 */
.MES32 {
background-color: var(--clr-2855);
color: #ffffff;
 }
/* Dark:33 */
.MES33 {
background-color: var(--clr-2847);
color: #ffffff;
 }
.MES33 {
background-color: var(--clr-2847);
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Shade 2:34 */
.MES34 {
background-color: var(--clr-2856);
 }
/* Shade 2:35 */
.MES35 {
background-color: var(--clr-2856);
 }
.MES35 {
background-color: var(--clr-2856);
 }
/* Carousel image Panel:36 */
.MES36 {
background-color: transparent;
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2850);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES36 {
background-color: transparent;
padding: 15px;

@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-2850);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Home carousel:37 */
.MES37 {
& .slider-arrow {background-color: 1;
color: var(--clr-2857);
font-size: 50px;
@media #{$medium-up} {
font-size: 50px;
};
@media #{$large-up} {
font-size: 50px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 1;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 1;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 6;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 6;}
}}
& .slick-dots{bottom:20px;
} }
/* Header Banner2:38 */
.MES38 {
background-color: var(--clr-2850);
color: #ffffff;
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-2848);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES38 {
background-color: var(--clr-2850);
color: #ffffff;
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-2848);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
cite.MEC38{
color: #ffffff;
}
/* Content Area White Bg:39 */
.MES39 {
background-color: #ffffff;
color: var(--clr-2845);
padding: 20px 5px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
 }
.MES39 {
background-color: #ffffff;
color: var(--clr-2845);
padding: 20px 5px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: var(--clr-2845);
 }
 }
a.MEC39 { color: var(--clr-2848);
&:hover { color: var(--clr-2858);}
 }
.MEC39 li {color: var(--clr-2847-flat);}
cite.MEC39{
color: var(--clr-2845);
}
/* Content Area Grey Bg:40 */
.MES40 {
background-color: var(--clr-2856);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
 }
.MES40 {
background-color: var(--clr-2856);
color: #ffffff;
padding: 20px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: #ffffff;
 }
 }
cite.MEC40{
color: #ffffff;
}
/* Dark blue colour:41 */
.MES41 {
background-color: var(--clr-2855);
color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES41 {
background-color: var(--clr-2855);
color: #ffffff;
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: #ffffff;
 }
 }
cite.MEC41{
color: #ffffff;
}
/* Buttons orange:42 */
.MES42 {
background-color: var(--clr-2848);
&:hover {background-color: var(--clr-2857);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Buttons pink:43 */
.MES43 {
background-color: var(--clr-2851);
&:hover {background-color: var(--clr-2859);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Buttons Dark Blue:44 */
.MES44 {
background-color: var(--clr-2855);
&:hover {background-color: var(--clr-2858);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Buttons Dark Teal:45 */
.MES45 {
background-color: var(--clr-2847);
&:hover {background-color: var(--clr-2854);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* :46 */
.MES46 {
 }
.MES46 {
 }
/* White text:47 */
.MES47 {
color: #ffffff;
font-size: 12.8px;
 }
.MES47 {
color: #ffffff;
font-size: 12.8px;
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: #ffffff;
 }
 }
cite.MEC47{
color: #ffffff;
font-size: 12.8px;
}
/* Orange line:48 */
.MES48 {
& > hr {border-width: 0 0 6px 0;}
& > hr {border-color: transparent transparent var(--clr-2857) transparent;}
 }
/* White Panel:49 */
.MES49 {
background-color: #ffffff;
color: var(--clr-2845);
 }
.MES49 {
background-color: #ffffff;
color: var(--clr-2845);
 }
cite.MEC49{
color: var(--clr-2845);
}
/* Course Window Blue:50 */
.MES50 {
background-color: var(--clr-2849);
color: var(--clr-2845);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES50 {
background-color: var(--clr-2849);
color: var(--clr-2845);
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: var(--clr-2848);
 }
 }
cite.MEC50{
color: var(--clr-2845);
}
/* Buttons Light Teal:51 */
.MES51 {
background-color: var(--clr-2856);
&:hover {background-color: var(--clr-2854);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* :52 */
details.MES52 {
& > summary{background-color: var(--clr-2849);
}
& > summary{padding: 10px 15px;}

& > article{padding: 20px;}

 }
/* Content Area Grey Bg:53 */
.MES53 {
background-color: var(--clr-2849);
color: var(--clr-2845);
padding: 20px 5px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
 }
.MES53 {
background-color: var(--clr-2849);
color: var(--clr-2845);
padding: 20px 5px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px;

}
h1.MEC53, h2.MEC53, h3.MEC53, h4.MEC53, h5.MEC53, h6.MEC53 { color: var(--clr-2848);
 }
 }
a.MEC53 { color: var(--clr-2848);
&:hover { color: var(--clr-2858);}
 }
.MEC53 li {color: var(--clr-2847-flat);}
cite.MEC53{
color: var(--clr-2845);
}
/* Pdf table right:54 */
.MES54 {
background-color: transparent;
padding: 20px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: var(--clr-2854);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES54 {
background-color: transparent;
padding: 20px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: var(--clr-2854);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Pdf table left:55 */
.MES55 {
background-color: transparent;
padding: 20px;

border-width: 0 1px 1px 0;
border-style: solid;
border-color: var(--clr-2854);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES55 {
background-color: transparent;
padding: 20px;

border-width: 0 1px 1px 0;
border-style: solid;
border-color: var(--clr-2854);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Large Popup Frame:56 */
.MES56 {
background-color: var(--clr-2860);
 }
.MES56 {
background-color: var(--clr-2860);
 }
/* Buttons green:57 */
.MES57 {
background-color: var(--clr-2861);
&:hover {background-color: var(--clr-2862);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Image Circle:58 */
.MES58 {
border-radius: 100%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES58 {
border-radius: 100%;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Menu 3:60 */
nav.me-Menu.MES60 {
& .menu-item.MEC60, & .menu-item.MEC60 > div.MEC60{ & > a.MEC60{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC60{color: var(--clr-2848);
}
 }
&.horizontal > .menu-item.MEC60 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC60 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC60 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC60 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 20px;}


& > .menu-item.MEC60.active { & > a{ color: var(--clr-2848);}
 }

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Navy Blue :61 */
.MES61 {
background-color: var(--clr-2855);
color: #ffffff;
 }
.MES61 {
background-color: var(--clr-2855);
color: #ffffff;
 }
cite.MEC61{
color: #ffffff;
}
